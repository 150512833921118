<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <h2>Contact Us</h2>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="contact-info-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-sm-6 col-md-6">
                        <div class="single-contact-info">
                            <i class="bx bx-envelope"></i>
                            <h3>Email Us:</h3>
                            <a href="mailto:info@dbk.cloud">info@dbk.cloud</a>
                            <a href="mailto:security@dbk.cloud">security@dbk.cloud</a>
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6 col-md-6">
                        <div class="single-contact-info">
                            <i class="bx bx-phone-call"></i>
                            <h3>Call Us:</h3>
                            <a href="tel:+(123)1800-567-8990">Tel. + (123) 1800-567-8990</a>
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6 col-md-6">
                        <div class="single-contact-info">
                            <i class="bx bx-location-plus"></i>
                            <h3>Texas</h3>
                            <a href="#">Katy TX 77450</a>
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6 col-md-6">
                        <div class="single-contact-info">
                            <i class="bx bx-support"></i>
                            <h3>Live Chat</h3>
                            <a href="#">live chat all the time with our company 24/7</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div id="map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d13859.885757923665!2d-95.7579264!3d29.72058655!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sus!4v1649124336538!5m2!1sen!2sus" ></iframe>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="main-contact-area pb-100">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="contact-wrap contact-pages mb-0">
                    <div class="contact-form">
                        <div class="section-title">
                            <h2 class="mb-0">Drop us a message for any query</h2>
                        </div>

                        <form id="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="name" class="form-control" placeholder="Your Name">
                                    </div>
                                </div>
    
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="email" name="email" class="form-control" placeholder="Your Email">
                                    </div>
                                </div>
    
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="phone-number"  class="form-control" placeholder="Your Phone">
                                    </div>
                                </div>
    
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="msg-subject" class="form-control" placeholder="Your Subject">
                                    </div>
                                </div>
    
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" cols="30" rows="5" placeholder="Your Message"></textarea>
                                    </div>
                                </div>
    
                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn btn-two">Send Message</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
