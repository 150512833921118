<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <h2>Services</h2>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/">Home </a></li>
                        <li>Pages</li>
                        <li>Services</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="technology-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>The Latest Hosting Server Technology</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum suspendisse.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-technology">
                    <div class="technology-icon-bg">
                        <img src="assets/img/shape/technology-icon-bg.png" alt="Image">
                        <i class="flaticon-settings"></i>
                    </div>

                    <h3>Easy To Setup</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                    <a class="read-more" routerLink="/shared-hosting">Read More <i class="bx bx-chevron-right"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-technology">
                    <div class="technology-icon-bg">
                        <img src="assets/img/shape/technology-icon-bg.png" alt="Image">
                        <i class="flaticon-password"></i>
                    </div>

                    <h3>Privacy Protected</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                    <a class="read-more" routerLink="/shared-hosting">Read More <i class="bx bx-chevron-right"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-technology">
                    <div class="technology-icon-bg">
                        <img src="assets/img/shape/technology-icon-bg.png" alt="Image">
                        <i class="flaticon-backup"></i>
                    </div>

                    <h3>Monthly Backup</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                    <a class="read-more" routerLink="/shared-hosting">Read More <i class="bx bx-chevron-right"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-technology">
                    <div class="technology-icon-bg">
                        <img src="assets/img/shape/technology-icon-bg.png" alt="Image">
                        <i class="flaticon-speedometer-1"></i>
                    </div>

                    <h3>Fast Performance</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                    <a class="read-more" routerLink="/shared-hosting">Read More <i class="bx bx-chevron-right"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-technology">
                    <div class="technology-icon-bg">
                        <img src="assets/img/shape/technology-icon-bg.png" alt="Image">
                        <i class="flaticon-database"></i>
                    </div>

                    <h3>Unlimited Database</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                    <a class="read-more" routerLink="/shared-hosting">Read More <i class="bx bx-chevron-right"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-technology">
                    <div class="technology-icon-bg">
                        <img src="assets/img/shape/technology-icon-bg.png" alt="Image">
                        <i class="flaticon-molecule"></i>
                    </div>

                    <h3>Multiple Network</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                    <a class="read-more" routerLink="/shared-hosting">Read More <i class="bx bx-chevron-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="pricing-area pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Choose The Best Hosting Plan</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum suspendisse.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-pricing">
                    <div class="pricing-top-heading">
                        <h3>Basic</h3>
                        <p>Build a website</p>
                    </div>
                    <span>$29.00 <sub>/Month</sub></span>

                    <ul>
                        <li><i class="flaticon-tick"></i> 10GB Storage Space</li>
                        <li><i class="flaticon-tick"></i> 50GB Bandwidth</li>
                        <li><i class="flaticon-tick"></i> 10 Free Sub-Domains</li>
                        <li><i class="flaticon-tick"></i> 10GB Storage Space</li>
                        <li><i class="flaticon-tick"></i> 50GB Bandwidth</li>
                        <li><i class="flaticon-tick"></i> 10 Free Sub-Domains</li>
                    </ul>
                    <a class="default-btn" routerLink="/">Get Started</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-pricing">
                    <div class="pricing-top-heading">
                        <h3>Comfort</h3>
                        <p>Build a website</p>
                    </div>
                    <span>$59.00 <sub>/Month</sub></span>

                    <ul>
                        <li><i class="flaticon-tick"></i> 15GB Storage Space</li>
                        <li><i class="flaticon-tick"></i> 50GB Bandwidth</li>
                        <li><i class="flaticon-tick"></i> 10 Free Sub-Domains</li>
                        <li><i class="flaticon-tick"></i> 15GB Storage Space</li>
                        <li><i class="flaticon-tick"></i> 100GB Bandwidth</li>
                        <li><i class="flaticon-tick"></i> 50 Free Sub-Domains</li>
                    </ul>
                    <a class="default-btn" routerLink="/">Get Started</a>
                </div>
                <span class="popular">Popular</span>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-pricing">
                    <div class="pricing-top-heading">
                        <h3>Premium</h3>
                        <p>Build a website</p>
                    </div>
                    <span>$79.00 <sub>/Month</sub></span>

                    <ul>
                        <li><i class="flaticon-tick"></i> 20GB Storage Space</li>
                        <li><i class="flaticon-tick"></i> 50GB Bandwidth</li>
                        <li><i class="flaticon-tick"></i> 10 Free Sub-Domains</li>
                        <li><i class="flaticon-tick"></i> 420GB Storage Space</li>
                        <li><i class="flaticon-tick"></i> 50GB Bandwidth</li>
                        <li><i class="flaticon-tick"></i> 50 Free Sub-Domains</li>
                    </ul>
                    <a class="default-btn" routerLink="/">Get Started</a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="services-area pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Powerful Hosting Services</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum suspendisse.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-technology">
                    <div class="technology-icon-bg">
                        <img src="assets/img/shape/technology-icon-bg.png" alt="Image">
                        <i class="flaticon-uptime"></i>
                    </div>
                    <h3>100% Uptime</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
                    <a class="read-more" routerLink="/shared-hosting">Read More <i class="bx bx-chevron-right"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-technology active">
                    <div class="technology-icon-bg">
                        <img src="assets/img/shape/technology-icon-bg.png" alt="Image">
                        <i class="flaticon-speedometer-1"></i>
                    </div>
                    <h3>Ultimate Speed</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
                    <a class="read-more" routerLink="/shared-hosting">Read More <i class="bx bx-chevron-right"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="single-technology">
                    <div class="technology-icon-bg">
                        <img src="assets/img/shape/technology-icon-bg.png" alt="Image">
                        <i class="flaticon-database-1"></i>
                    </div>
                    <h3>Money-Back Guarantee</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
                    <a class="read-more" routerLink="/shared-hosting">Read More <i class="bx bx-chevron-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="technology-area pb-70">
    <div class="container">
        <div class="section-title">
            <h2>The Latest Hosting Server Technology</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum suspendisse.</p>
        </div>
        
        <div class="technology-wrap owl-carousel owl-theme">
            <div class="single-technology">
                <div class="technology-icon-bg">
                    <img src="assets/img/shape/technology-icon-bg.png" alt="Image">
                    <i class="flaticon-settings"></i>
                </div>

                <h3>Easy To Setup</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                <a class="read-more" routerLink="/shared-hosting">Read More <i class="bx bx-chevron-right"></i></a>
            </div>

            <div class="single-technology">
                <div class="technology-icon-bg">
                    <img src="assets/img/shape/technology-icon-bg.png" alt="Image">
                    <i class="flaticon-password"></i>
                </div>

                <h3>Privacy Protected</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                <a class="read-more" routerLink="/shared-hosting">Read More <i class="bx bx-chevron-right"></i></a>
            </div>

            <div class="single-technology">
                <div class="technology-icon-bg">
                    <img src="assets/img/shape/technology-icon-bg.png" alt="Image">
                    <i class="flaticon-backup"></i>
                </div>

                <h3>Monthly Backup</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                <a class="read-more" routerLink="/shared-hosting">Read More <i class="bx bx-chevron-right"></i></a>
            </div>

            <div class="single-technology">
                <div class="technology-icon-bg">
                    <img src="assets/img/shape/technology-icon-bg.png" alt="Image">
                    <i class="flaticon-speedometer-1"></i>
                </div>

                <h3>Fast Performance</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                <a class="read-more" routerLink="/shared-hosting">Read More <i class="bx bx-chevron-right"></i></a>
            </div>
        </div>
    </div>
</section>

<section class="hosting-services-area pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Hosta Hosting Services</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum suspendisse.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-hosting-service">
                    <i class="flaticon-settings"></i>
                    <h3>Storage & Backup</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-hosting-service">
                    <i class="flaticon-network"></i>
                    <h3>Easy Share</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-hosting-service">
                    <i class="flaticon-diagram"></i>
                    <h3>Cloud Hosting</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-hosting-service">
                    <i class="flaticon-backup"></i>
                    <h3>Domain Registration</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-hosting-service">
                    <i class="flaticon-database-1"></i>
                    <h3>Free SSL Certificate</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-hosting-service">
                    <i class="flaticon-network"></i>
                    <h3>Cloud Hosting</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                </div>
            </div>
        </div>
    </div>
</section>