<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <h2>About Us</h2>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="customer-support-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="customer-title">
                    <h2>DBK Cloud</h2>
                    <p>We are a cloud base customer first company. Our goal is to help MSP with all their cloud and licensing needs. We have build a platform to help resellers and MSP manage licensing and sell direcly to their cosumers all the software and hardware they need for a day to day operations.
                    Our goal is to become the #1 partner for all small and medium size MSPs for all technical needs.</p>
                </div>

                <ul class="support-list">
                    <li>Hardware reselling store</li>
                    <li>Software licensing reselling store</li>
                    <li>Cloud optimization and cost savings</li>
                    <li>Cloud architecture </li>
                    <li>Cloud migration </li>
                </ul>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="support-img">
                    <img src="assets/img/about/rocket.jpeg" alt="Image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="solutions-area pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="solutions-img">
                    <img src="assets/img/about/team.jpeg" alt="Image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="solutions-wrap">
                    <div class="solutions-title">
                        <h2>Team</h2>
                        <p>Our team is comprise of Cloud Expert Architects, Developers, Administratos and DevOps enginers with a focust on FinOps and Cost Optimization</p>
                    </div>

                    <ul class="solutions-list">
                        <li><i class="flaticon-right"></i> After you purchase a hosting plan</li>
                        <li><i class="flaticon-right"></i> Next to the hosting account, you want to use, click Manage</li>
                        <li><i class="flaticon-right"></i> Select or enter the domain name you want to use</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
