import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/layouts/preloader/preloader.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { NavbarComponent } from './components/layouts/navbar/navbar.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { HomeFourComponent } from './components/pages/home-four/home-four.component';
import { HomeFiveComponent } from './components/pages/home-five/home-five.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { AffiliateComponent } from './components/pages/affiliate/affiliate.component';
import { TeamComponent } from './components/pages/team/team.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { LogInComponent } from './components/pages/log-in/log-in.component';
import { SignUpComponent } from './components/pages/sign-up/sign-up.component';
import { RecoverPasswordComponent } from './components/pages/recover-password/recover-password.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { NewsGridComponent } from './components/pages/news-grid/news-grid.component';
import { NewsLeftSidebarComponent } from './components/pages/news-left-sidebar/news-left-sidebar.component';
import { NewsRightSidebarComponent } from './components/pages/news-right-sidebar/news-right-sidebar.component';
import { NewsDetailsComponent } from './components/pages/news-details/news-details.component';
import { DomainComponent } from './components/pages/domain/domain.component';
import { SharedHostingComponent } from './components/pages/shared-hosting/shared-hosting.component';
import { ResellerHostingComponent } from './components/pages/reseller-hosting/reseller-hosting.component';
import { DedicatedHostingComponent } from './components/pages/dedicated-hosting/dedicated-hosting.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ErrorComponent } from './components/pages/error/error.component';

@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    FooterComponent,
    HomeOneComponent,
    NavbarComponent,
    HomeTwoComponent,
    HomeThreeComponent,
    HomeFourComponent,
    HomeFiveComponent,
    AboutComponent,
    ServicesComponent,
    AffiliateComponent,
    TeamComponent,
    FaqComponent,
    LogInComponent,
    SignUpComponent,
    RecoverPasswordComponent,
    TermsConditionsComponent,
    PrivacyPolicyComponent,
    ContactComponent,
    PricingComponent,
    NewsGridComponent,
    NewsLeftSidebarComponent,
    NewsRightSidebarComponent,
    NewsDetailsComponent,
    DomainComponent,
    SharedHostingComponent,
    ResellerHostingComponent,
    DedicatedHostingComponent,
    ComingSoonComponent,
    ErrorComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
