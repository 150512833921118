<section class="domain-banner-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row">
                    <div class="banner-text">
                        <h1>Search Your Perfect Domain Name Here </h1>
                        <p>Up to 50% Off Domain and Hosting, Starting from $ 2.50/Month Free SSL Certificate, Money-Back Guarantee.</p>	

                        <div class="search-domain">
                            <form class="domain-wrap only-domain">
                                <input type="text" class="input-domain" placeholder="Find your domain name here" name="Domain">
                                <button class="default-btn" type="submit" value="submit">Search</button>

                                <select class="select-domain-name">
                                    <option>.com</option>
                                    <option>.net</option>
                                    <option>.info</option>
                                    <option>.store</option>
                                    <option>.biz</option>
                                    <option>.xyz</option>
                                    <option>.rog</option>
                                </select>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="domain-pricing-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>The Cheapest Domain Pricing</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum suspendisse.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-domain">
                    <div class="domain-name-wrap">
                        <img src="assets/img/shape/technology-icon-bg.png" alt="">
                        <div class="domain-name">
                            <h3>.com</h3>
                        </div>
                    </div>

                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt, consequatur sequi molestias</p>
                    <span>$10 <sub>/year</sub></span>
                    <a class="default-btn" routerLink="/">Register</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-domain">
                    <div class="domain-name-wrap">
                        <img src="assets/img/shape/technology-icon-bg.png" alt="">
                        <div class="domain-name">
                            <h3>.net</h3>
                        </div>
                    </div>

                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt, consequatur sequi molestias</p>
                    <span>$15 <sub>/year</sub></span>
                    <a class="default-btn" routerLink="/">Register</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-domain">
                    <div class="domain-name-wrap">
                        <img src="assets/img/shape/technology-icon-bg.png" alt="">
                        <div class="domain-name">
                            <h3>.org</h3>
                        </div>
                    </div>

                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt, consequatur sequi molestias</p>
                    <span>$20 <sub>/year</sub></span>
                    <a class="default-btn" routerLink="/">Register</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-domain">
                    <div class="domain-name-wrap">
                        <img src="assets/img/shape/technology-icon-bg.png" alt="">
                        <div class="domain-name">
                            <h3>.xyz</h3>
                        </div>
                    </div>

                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt, consequatur sequi molestias</p>
                    <span>$20 <sub>/year</sub></span>
                    <a class="default-btn" routerLink="/">Register</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-domain">
                    <div class="domain-name-wrap">
                        <img src="assets/img/shape/technology-icon-bg.png" alt="">
                        <div class="domain-name">
                            <h3>.biz</h3>
                        </div>
                    </div>

                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt, consequatur sequi molestias</p>
                    <span>$25 <sub>/year</sub></span>
                    <a class="default-btn" routerLink="/">Register</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-domain">
                    <div class="domain-name-wrap">
                        <img src="assets/img/shape/technology-icon-bg.png" alt="">
                        <div class="domain-name">
                            <h3>.info</h3>
                        </div>
                    </div>

                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt, consequatur sequi molestias</p>
                    <span>$30 <sub>/year</sub></span>
                    <a class="default-btn" routerLink="/">Register</a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="domain-pricing-list-area pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="domain-pricing-list">
                    <h2>Popular Domain Zones:</h2>

                    <div class="table-responsive">
                        <table class="domain-price">
                            <tr class="domain-mane-title">
                                <th>Domain Zone</th>
    
                                <th>Price/Year</th>
    
                                <th>Register</th>
                            </tr>
                            
                            <tr>
                                <td class="table-domain-name">
                                    .com <span class="sale">sale</span>
                                </td>
    
                                <td class="table-domain-price">
                                    $1.99
                                </td>
    
                                <td class="buy-btn-wrapper">
                                    <div class="btn-wrapper">
                                        <a routerLink="/" class="default-btn">Register</a>
                                    </div>
                                </td>
                            </tr>
    
                            <tr>
                                <td class="table-domain-name">
                                    .net
                                </td>
    
                                <td class="table-domain-price">
                                    $2.99 <span class="save">save 50%</span>
                                </td>
    
                                <td class="buy-btn-wrapper">
                                    <div class="btn-wrapper">
                                        <a routerLink="/" class="default-btn">Register</a>
                                    </div>
                                </td>
                            </tr>
    
                            <tr>
                                <td class="table-domain-name">
                                    .org
                                </td>
    
                                <td class="table-domain-price">
                                    $3.99
                                </td>
    
                                <td class="buy-btn-wrapper">
                                    <div class="btn-wrapper">
                                        <a routerLink="/" class="default-btn">Register</a>
                                    </div>
                                </td>
                            </tr>
    
                            <tr>
                                <td class="table-domain-name">
                                    .store<span class="sale">sale</span>
                                </td>
    
                                <td class="table-domain-price">
                                    $4.99
                                </td>
    
                                <td class="buy-btn-wrapper">
                                    <div class="btn-wrapper">
                                        <a routerLink="/" class="default-btn">Register</a>
                                    </div>
                                </td>
                            </tr>
    
                            <tr>
                                <td class="table-domain-name">
                                    .pro
                                </td>
    
                                <td class="table-domain-price">
                                    $5.99 <span class="save">save 50%</span>
                                </td>
    
                                <td class="buy-btn-wrapper">
                                    <div class="btn-wrapper">
                                        <a routerLink="/" class="default-btn">Register</a>
                                    </div>
                                </td>
                            </tr>
    
                            <tr>
                                <td class="table-domain-name">
                                    .vip
                                </td>
    
                                <td class="table-domain-price">
                                    $6.99
                                </td>
    
                                <td class="buy-btn-wrapper">
                                    <div class="btn-wrapper">
                                        <a routerLink="/" class="default-btn">Register</a>
                                    </div>
                                </td>
                            </tr>
    
                            <tr>
                                <td class="table-domain-name">
                                    .me <span class="sale">sale</span>
                                </td>
    
                                <td class="table-domain-price">
                                    $7.99
                                </td>
    
                                <td class="buy-btn-wrapper">
                                    <div class="btn-wrapper">
                                        <a routerLink="/" class="default-btn">Register</a>
                                    </div>
                                </td>
                            </tr>
    
                            <tr>
                                <td class="table-domain-name">
                                    .co
                                </td>
    
                                <td class="table-domain-price">
                                    $8.99 <span class="save">save 50%</span>
                                </td>
    
                                <td class="buy-btn-wrapper">
                                    <div class="btn-wrapper">
                                        <a routerLink="/" class="default-btn">Register</a>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="domain-pricing-list">
                    <h2>International Domain Zones:</h2>

                    <div class="table-responsive">
                        <table class="domain-price">
                            <tr class="domain-mane-title">
                                <th>Domain Zone</th>

                                <th>Price/Year</th>

                                <th>Register</th>
                            </tr>

                            <tr>
                                <td class="table-domain-name">
                                    .agency
                                </td>

                                <td class="table-domain-price">
                                    $9.99 <span class="save">save 50%</span>
                                </td>

                                <td class="buy-btn-wrapper">
                                    <div class="btn-wrapper">
                                        <a routerLink="/" class="default-btn">Register</a>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td class="table-domain-name">
                                    .biz <span class="sale">sale</span>
                                </td>

                                <td class="table-domain-price">
                                    $10.99
                                </td>

                                <td class="buy-btn-wrapper">
                                    <div class="btn-wrapper">
                                        <a routerLink="/" class="default-btn">Register</a>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td class="table-domain-name">
                                    .ga
                                </td>

                                <td class="table-domain-price">
                                    $11.99
                                </td>

                                <td class="buy-btn-wrapper">
                                    <div class="btn-wrapper">
                                        <a routerLink="/" class="default-btn">Register</a>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td class="table-domain-name">
                                    .click
                                </td>

                                <td class="table-domain-price">
                                    $12.99 <span class="save">save 50%</span>
                                </td>

                                <td class="buy-btn-wrapper">
                                    <div class="btn-wrapper">
                                        <a routerLink="/" class="default-btn">Register</a>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td class="table-domain-name">
                                    .me <span class="sale">sale</span>
                                </td>

                                <td class="table-domain-price">
                                    $13.99
                                </td>

                                <td class="buy-btn-wrapper">
                                    <div class="btn-wrapper">
                                        <a routerLink="/" class="default-btn">Register</a>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td class="table-domain-name">
                                    .de
                                </td>

                                <td class="table-domain-price">
                                    $14.99
                                </td>

                                <td class="buy-btn-wrapper">
                                    <div class="btn-wrapper">
                                        <a routerLink="/" class="default-btn">Register</a>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td class="table-domain-name">
                                    .education
                                </td>

                                <td class="table-domain-price">
                                    $15.99 <span class="save">save 50%</span>
                                </td>

                                <td class="buy-btn-wrapper">
                                    <div class="btn-wrapper">
                                        <a routerLink="/" class="default-btn">Register</a>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td class="table-domain-name">
                                    .tk <span class="sale">sale</span>
                                </td>

                                <td class="table-domain-price">
                                    $16.99
                                </td>

                                <td class="buy-btn-wrapper">
                                    <div class="btn-wrapper">
                                        <a routerLink="/" class="default-btn">Register</a>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="protected-area pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Why Go With Hosta</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum suspendisse.</p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="frequently-content-wrap">
                    <ul>
                        <li>
                            <i class="flaticon-database-1"></i>
                            <h3>What is wohust hosting</h3>
                            <p>Lorem ipsum consectetur dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun.</p>
                        </li>
                        <li>
                            <i class="flaticon-diagram"></i>
                            <h3>Why choose wohust hosting</h3>
                            <p>Lorem ipsum consectetur dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun.</p>
                        </li>
                        <li>
                            <i class="flaticon-uptime"></i>
                            <h3>If this an affiliate program?</h3>
                            <p>Lorem ipsum consectetur dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun.</p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="frequently-content-wrap">
                    <ul>
                        <li>
                            <i class="flaticon-migrating"></i>
                            <h3>Award-wining support</h3>
                            <p>Lorem ipsum consectetur dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun.</p>
                        </li>
                        <li>
                            <i class="flaticon-network-1"></i>
                            <h3>The visoin for our mision</h3>
                            <p>Lorem ipsum consectetur dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun.</p>
                        </li>
                        <li>
                            <i class="flaticon-database"></i>
                            <h3>All in one place</h3>
                            <p>Lorem ipsum consectetur dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="dedicated-server-area pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="server-content-wrap">
                    <h2>Each Domain Include</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel.</p>

                    <ul>
                        <li>
                            <i class="flaticon-migrating"></i>
                            <span>Free Migration</span>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </li>
                        <li>
                            <i class="flaticon-migrating"></i>
                            <span>Server Management Option</span>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </li>
                        <li>
                            <i class="flaticon-verified"></i>
                            <span>Airtight Security</span>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="include-img text-center">
                    <img src="assets/img/include-img.png" alt="Image">
                </div>
            </div>
        </div>
    </div>
</section>