<nav class="navbar navbar-expand-md navbar-light">
    <div class="container">
        <a class="navbar-brand" routerLink="/">
            <img src="assets/img/white-logo.png" alt="Logo">
        </a>

        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav">
                <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"><a routerLink="/" class="nav-link">Home</a></li>
                <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"><a routerLink="/about" class="nav-link">About Us</a></li>
                <li class="nav-item">
                    <a href="javascript:void(0)" class="nav-link">Services <i class='bx bx-chevron-down'></i></a>

                    <ul class="dropdown-menu">
                        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"><a routerLink="/coming-soon" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Digital Reseller Store</a></li>
                        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"><a routerLink="/coming-soon" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">MSP Services</a></li>
                        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"><a routerLink="/coming-soon" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Cloud Architecute</a></li>
                        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"><a routerLink="/coming-soon" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Cost Optimization</a></li>
                    </ul>
                </li>

                <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"><a routerLink="/news-grid" class="nav-link">Articles</a></li>
                <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"><a routerLink="/contact" class="nav-link">Contact</a></li>
            </ul>

            <div class="others-option">
                <a class="default-btn" routerLink="/sign-up">Sign Up</a>
            </div>
        </div>
    </div>
</nav>
