<section class="hosting-solution-area ptb-100" style="height:600px; padding-top:300px" >
    <div class="container">
        <div class="hosting-solution-wrap">
            <h2>Start selling you digital products NOW!</h2>
            <a class="default-btn" routerLink="/shared-hosting">Get a Quote</a>
            <a class="default-btn active" routerLink="/contact">Contact Us</a>
        </div>
    </div>

    <div class="shape shape-one"><img src="assets/img/shape/hosting-solution-one.png" alt=""></div>
    <div class="shape shape-two"><img src="assets/img/shape/hosting-solution-two.png" alt=""></div>
</section>

<section class="customer-support-area pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="customer-title">
                    <h2>Concierge service and Online Customer Support</h2>
                    <p>We guide out customers on all aspects of the online online activities. Out concierge support will take care of supporting the platform, administrative task, data collection, reporting and changes to the strategy ensuring our customers success</p>
                </div>

                <ul class="support-list">
                    <li>Platform Configuration</li>
                    <li>Reseller Support</li>
                    <li>Customer-Customer Support</li>
                    <li>Vendor support</li>
                    <li>Integration management support</li>
                    <li>Feature Request</li>
                </ul>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="support-img">
                    <img src="assets/img/home/customersupport.jpeg" alt="Image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="solutions-area pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="solutions-img">
                    <img src="assets/img/home/store.jpeg" alt="Image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="solutions-wrap">
                    <div class="solutions-title">
                        <h2>Complete store platform and custom integrations</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt  labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commo o viverra maecenas accumsan lacus.</p>
                    </div>
                    <ul class="solutions-list">
                        <li><i class="flaticon-right"></i> Full White Label support</li>
                        <li><i class="flaticon-right"></i> Microsoft ESD reselling integration</li>
                        <li><i class="flaticon-right"></i> Microsoft CSP reselling integration</li>
                        <li><i class="flaticon-right"></i> Custom Token VAULT support</li>
                        <li><i class="flaticon-right"></i> Custom Hardware inventory</li>
                        <li><i class="flaticon-right"></i> Domain purchase and certificate integration </li>
                        <li><i class="flaticon-right"></i> Custom ERP order syncronization services </li>
                        <li><i class="flaticon-right"></i> Shipping Services Integration (UPS, USPS, FedEx, DHL, and others)</li>
                        <li><i class="flaticon-right"></i> Stripe Payment integration</li>
                        <li><i class="flaticon-right"></i> Quickbooks integration</li>
                        <li><i class="flaticon-right"></i> Freshdesk integration</li>
                    </ul>
                    <a class="default-btn" routerLink="/">Get The Service</a>	
                </div>
            </div>
        </div>
    </div>
</section>

<section class="location-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Store Deployments</h2>
            <p>Keeping out services close to our customers. Our store deployments are hosted on the azure edge, with backend systems been able to deploy on the folowing azure regions</p>
        </div>

        <div class="map-img">
            <img src="assets/img/map-img.png" alt="Image">

            <div class="single-location video-btn">
                <i class="bx bx-location-plus"></i>
                <span>South Central US</span>
            </div>

            <div class="single-location location-two video-btn">
                <i class="bx bx-location-plus"></i>
                <span>Brazil South</span>
            </div>

            <div class="single-location location-three video-btn">
                <i class="bx bx-location-plus"></i>
                <span>France Central</span>
            </div>

            <div class="single-location location-five video-btn">
                <i class="bx bx-location-plus"></i>
                <span>South Africa North</span>
            </div>

            <div class="single-location location-six video-btn">
                <i class="bx bx-location-plus"></i>
                <span>Australia East</span>
            </div>
        </div>
    </div>
</section>

<section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Articles</h2>
            <p>bits of free relevant knowlage </p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <a href="https://www.linkedin.com/pulse/tagging-cost-management-cloud-engineering-chris-cabezudo/" class="d-block text-center">
                    <img src="https://media-exp1.licdn.com/dms/image/C4E12AQEj99A6G1wzeg/article-cover_image-shrink_600_2000/0/1627419141181?e=1654732800&v=beta&t=7mmvoMNhgR97xV-MoPKldg81VvYGqndzYNS3SHVGfSs" alt="Image"></a>

                    <div class="blog-content-wrap">
                        <ul>
                            <li><a routerLink="/"><i class="flaticon-male"></i> Chris C2</a></li>
                            <li class="date"><i class="flaticon-appointment"></i> 20 Feb 2020</li>
                        </ul>
                        <h3><a rhref="https://www.linkedin.com/pulse/tagging-cost-management-cloud-engineering-chris-cabezudo/">Tagging - Cost Management for Cloud Engineering</a></h3>
                        <p>In this article, I want to expand on Microsoft Tagging recommendations and focus on software development teams.</p>
                        <a class="read-more"  href="https://www.linkedin.com/pulse/tagging-cost-management-cloud-engineering-chris-cabezudo/">Read More <i class="bx bx-chevron-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <a href="https://www.linkedin.com/pulse/getting-cloud-chris-cabezudo/" class="d-block text-center">
                    <img src="https://media-exp1.licdn.com/dms/image/C4E12AQEL3Jw3XJHctA/article-cover_image-shrink_720_1280/0/1579909312019?e=2147483647&v=beta&t=-tz1xR-fcclUh6s1gl3Q3_-8kXUEX8f_xdpw5InH5Ik" alt="Image"></a>

                    <div class="blog-content-wrap">
                        <ul>
                            <li><a routerLink="/"><i class="flaticon-male"></i> Chris C2</a></li>
                            <li class="date"><i class="flaticon-appointment"></i> Jul 27, 2021</li>
                        </ul>
                        <h3><a href="https://www.linkedin.com/pulse/getting-cloud-chris-cabezudo/">Getting to the cloud</a></h3>
                        <p>Most teams, don't have a dedicated Azure Engineer or Cloud Architect. This job is taken by the developers eager to learn and use this new thing everyone is talking about.</p>
                        <a class="read-more" href="https://www.linkedin.com/pulse/getting-cloud-chris-cabezudo/">Read More <i class="bx bx-chevron-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-blog-post">
                    <a href="https://www.linkedin.com/pulse/conquer-your-office-365-bill-reduce-overall-cost-chris-cabezudo?trk=pulse-article_more-articles_related-content-card" class="d-block text-center">
                    <img src="https://media-exp1.licdn.com/dms/image/C4E12AQFBOF9kCLkOzA/article-cover_image-shrink_720_1280/0/1578438291708?e=2147483647&v=beta&t=4jGg9JRiGln81WKcvyoTGLDrvbflQ1ANm0UKeOdSbeY" alt="Image"></a>

                    <div class="blog-content-wrap">
                        <ul>
                            <li><a routerLink="/"><i class="flaticon-male"></i> Chris C2</a></li>
                            <li class="date"><i class="flaticon-appointment"></i> Jan 7, 2020</li>
                        </ul>
                        <h3><a href="https://www.linkedin.com/pulse/conquer-your-office-365-bill-reduce-overall-cost-chris-cabezudo?trk=pulse-article_more-articles_related-content-card">Conquer your MS 365 bill and reduce your overall cost.</a></h3>
                        <p>
                            5 Tips on how to manage control your MS 365 Bill
                            1.Don’t over provision or purchase licenses
                        </p>
                        <a class="read-more" href="https://www.linkedin.com/pulse/conquer-your-office-365-bill-reduce-overall-cost-chris-cabezudo?trk=pulse-article_more-articles_related-content-card">Read More <i class="bx bx-chevron-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
