<section class="main-banner-area main-banner-area-three">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="banner-text">
                            <h1>Powerful Hosting Plan For Your Dream Website</h1>
                            <p>Up to 50% Off Domain and Hosting, Starting from $2.50/Month Free SSL Certificate, Money-Back Guarantee!</p>

                            <div class="search-domain">
                                <form class="domain-wrap">
                                    <input type="text" class="input-domain" placeholder="Find your domain name here" name="Domain">
                                    <button class="default-btn" type="submit" value="submit">Search</button>

                                    <select class="select-domain-name">
                                        <option>.com</option>
                                        <option>.net</option>
                                        <option>.info</option>
                                        <option>.store</option>
                                        <option>.biz</option>
                                        <option>.xyz</option>
                                        <option>.rog</option>
                                    </select>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="banner-img">
                            <img class="banner-main-img" src="assets/img/banner-three/banner-main-img.png" alt="Image">
                            <div class="banner-main-img-shape">
                                <img src="assets/img/banner-three/banner-main-img-shape.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="white-shape"><img src="assets/img/banner-three/white-shape-bottom.png" alt="Image"></div>

    <div class="shape-line-one">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-two.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-three.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-two.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-three.png" alt="Image">
    </div>
</section>

<section class="technology-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>The Latest Hosting Server Technology</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum suspendisse.</p>
        </div>
        
        <div class="technology-wrap owl-carousel owl-theme">
            <div class="single-technology">
                <div class="technology-icon-bg">
                    <img src="assets/img/shape/technology-icon-bg.png" alt="Image">
                    <i class="flaticon-settings"></i>
                </div>

                <h3>Easy To Setup</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                <a class="read-more" routerLink="/shared-hosting">Read More <i class="bx bx-chevron-right"></i></a>
            </div>

            <div class="single-technology">
                <div class="technology-icon-bg">
                    <img src="assets/img/shape/technology-icon-bg.png" alt="Image">
                    <i class="flaticon-password"></i>
                </div>

                <h3>Privacy Protected</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                <a class="read-more" routerLink="/shared-hosting">Read More <i class="bx bx-chevron-right"></i></a>
            </div>

            <div class="single-technology">
                <div class="technology-icon-bg">
                    <img src="assets/img/shape/technology-icon-bg.png" alt="Image">
                    <i class="flaticon-backup"></i>
                </div>

                <h3>Monthly Backup</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                <a class="read-more" routerLink="/shared-hosting">Read More <i class="bx bx-chevron-right"></i></a>
            </div>

            <div class="single-technology">
                <div class="technology-icon-bg">
                    <img src="assets/img/shape/technology-icon-bg.png" alt="Image">
                    <i class="flaticon-speedometer-1"></i>
                </div>

                <h3>Fast Performance</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                <a class="read-more" routerLink="/shared-hosting">Read More <i class="bx bx-chevron-right"></i></a>
            </div>
        </div>
    </div>
</section>

<section class="customer-support-area pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="customer-title">
                    <h2>24/7 Online Customer Support</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt  labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commo o viverra maecenas accumsan lacus.</p>
                </div>

                <ul class="support-list">
                    <li>Next to the hosting account, you want to use, click Manage</li>
                    <li>Select or enter the domain name you want to use</li>
                    <li>After you purchase a hosting plan</li>
                    <li>Why choose wohust hosting</li>
                </ul>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="support-img">
                    <img src="assets/img/support-img.png" alt="Image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="solutions-area pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="solutions-img">
                    <img src="assets/img/solutions-img.png" alt="Image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="solutions-wrap">
                    <div class="solutions-title">
                        <h2>Complete Cloud Hosting Solutions</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt  labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commo o viverra maecenas accumsan lacus.</p>
                    </div>
                    <ul class="solutions-list">
                        <li><i class="flaticon-right"></i> After you purchase a hosting plan</li>
                        <li><i class="flaticon-right"></i> Next to the hosting account, you want to use, click Manage</li>
                        <li><i class="flaticon-right"></i> Select or enter the domain name you want to use</li>
                    </ul>
                    <a class="default-btn" routerLink="/">Get The Service</a>	
                </div>
            </div>
        </div>
    </div>
</section>

<section class="hosting-solution-area ptb-100">
    <div class="container">
        <div class="hosting-solution-wrap">
            <h2>We Don't Compromise With The Best Hosting Solution</h2>
            <a class="default-btn" routerLink="/shared-hosting">Get Started</a>
            <a class="default-btn active" routerLink="/contact">Contact Us</a>
        </div>
    </div>

    <div class="shape shape-one"><img src="assets/img/shape/hosting-solution-one.png" alt=""></div>
    <div class="shape shape-two"><img src="assets/img/shape/hosting-solution-two.png" alt=""></div>
</section>

<section class="pricing-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Choose The Best Hosting Plan</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum suspendisse.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-pricing">
                    <div class="pricing-top-heading">
                        <h3>Basic</h3>
                        <p>Build a website</p>
                    </div>
                    <span>$29.00 <sub>/Month</sub></span>

                    <ul>
                        <li><i class="flaticon-tick"></i> 10GB Storage Space</li>
                        <li><i class="flaticon-tick"></i> 50GB Bandwidth</li>
                        <li><i class="flaticon-tick"></i> 10 Free Sub-Domains</li>
                        <li><i class="flaticon-tick"></i> 10GB Storage Space</li>
                        <li><i class="flaticon-tick"></i> 50GB Bandwidth</li>
                        <li><i class="flaticon-tick"></i> 10 Free Sub-Domains</li>
                    </ul>
                    <a class="default-btn" routerLink="/">Get Started</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-pricing">
                    <div class="pricing-top-heading">
                        <h3>Comfort</h3>
                        <p>Build a website</p>
                    </div>
                    <span>$59.00 <sub>/Month</sub></span>

                    <ul>
                        <li><i class="flaticon-tick"></i> 15GB Storage Space</li>
                        <li><i class="flaticon-tick"></i> 50GB Bandwidth</li>
                        <li><i class="flaticon-tick"></i> 10 Free Sub-Domains</li>
                        <li><i class="flaticon-tick"></i> 15GB Storage Space</li>
                        <li><i class="flaticon-tick"></i> 100GB Bandwidth</li>
                        <li><i class="flaticon-tick"></i> 50 Free Sub-Domains</li>
                    </ul>
                    <a class="default-btn" routerLink="/">Get Started</a>
                </div>
                <span class="popular">Popular</span>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-pricing">
                    <div class="pricing-top-heading">
                        <h3>Premium</h3>
                        <p>Build a website</p>
                    </div>
                    <span>$79.00 <sub>/Month</sub></span>

                    <ul>
                        <li><i class="flaticon-tick"></i> 20GB Storage Space</li>
                        <li><i class="flaticon-tick"></i> 50GB Bandwidth</li>
                        <li><i class="flaticon-tick"></i> 10 Free Sub-Domains</li>
                        <li><i class="flaticon-tick"></i> 420GB Storage Space</li>
                        <li><i class="flaticon-tick"></i> 50GB Bandwidth</li>
                        <li><i class="flaticon-tick"></i> 50 Free Sub-Domains</li>
                    </ul>
                    <a class="default-btn" routerLink="/">Get Started</a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="our-team-area pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Meet Our Team</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum suspendisse.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <img src="assets/img/team/team-bg.png" alt="Image">

                    <div class="team-man">
                        <img src="assets/img/team/team-img-one.png" alt="Image">
                    </div>

                    <h3>Jenifar Ikra</h3>
                    <span>Head of Idae</span>

                    <ul class="social-icon">
                        <li><a href="#"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="#"><i class="bx bxl-instagram"></i></a></li>
                        <li><a href="#"><i class="bx bxl-linkedin"></i></a></li>
                        <li><a href="#"><i class="bx bxl-youtube"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <img src="assets/img/team/team-bg.png" alt="Image">

                    <div class="team-man">
                        <img src="assets/img/team/team-img-two.png" alt="Image">
                    </div>

                    <h3>Jenia Popy</h3>
                    <span>UI/UX Designer</span>

                    <ul class="social-icon">
                        <li><a href="#"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="#"><i class="bx bxl-instagram"></i></a></li>
                        <li><a href="#"><i class="bx bxl-linkedin"></i></a></li>
                        <li><a href="#"><i class="bx bxl-youtube"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-team">
                    
                    <img src="assets/img/team/team-bg.png" alt="Image">
                    <div class="team-man">
                        <img src="assets/img/team/team-img-three.png" alt="Image">
                    </div>

                    <h3>Alisa Merina</h3>
                    <span>Full Stack Dev</span>

                    <ul class="social-icon">
                        <li><a href="#"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="#"><i class="bx bxl-instagram"></i></a></li>
                        <li><a href="#"><i class="bx bxl-linkedin"></i></a></li>
                        <li><a href="#"><i class="bx bxl-youtube"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="location-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Data Center Locations</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum suspendisse.</p>
        </div>

        <div class="map-img">
            <img src="assets/img/map-img.png" alt="Image">

            <div class="single-location video-btn">
                <i class="bx bx-location-plus"></i>
                <span>Russia</span>
            </div>

            <div class="single-location location-two video-btn">
                <i class="bx bx-location-plus"></i>
                <span>London</span>
            </div>

            <div class="single-location location-three video-btn">
                <i class="bx bx-location-plus"></i>
                <span>Canada</span>
            </div>

            <div class="single-location location-four video-btn">
                <i class="bx bx-location-plus"></i>
                <span>Brazil</span>
            </div>

            <div class="single-location location-five video-btn">
                <i class="bx bx-location-plus"></i>
                <span>Australia</span>
            </div>

            <div class="single-location location-six video-btn">
                <i class="bx bx-location-plus"></i>
                <span>Washington</span>
            </div>
        </div>

        <ul class="by-domain">
            <li><span>Russia</span> (50)</li>
            <li><span>London</span> (90)</li>
            <li><span>Canada</span> (80)</li>
            <li><span>Brazil</span> (40)</li>
            <li><span>Australia</span> (60)</li>
            <li><span>Washington</span> (70)</li>
        </ul>
    </div>
</section>

<section class="frequently-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Frequently Asked Questions</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum suspendisse.</p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="frequently-img">
                    <img src="assets/img/frequently-img.png" alt="Image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="frequently-content-wrap">
                    <ul>
                        <li>
                            <i class="flaticon-database"></i>
                            <h3>What is wohust hosting?</h3>
                            <p>Lorem ipsum consectetur dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun.</p>
                        </li>
                        <li>
                            <i class="flaticon-database-1"></i>
                            <h3>Why choose wohust hosting?</h3>
                            <p>Lorem ipsum consectetur dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun.</p>
                        </li>
                        <li>
                            <i class="flaticon-uptime"></i>
                            <h3>If this an affiliate program?</h3>
                            <p>Lorem ipsum consectetur dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="client-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>What Our Client Say</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum suspendisse.</p>
        </div>
        
        <div class="row">
            <div class="client-wrap owl-carousel owl-theme">
                <div class="single-client">
                    <img src="assets/img/client/client-img-one.jpg" alt="Image">
                    <h3>Denial Peer</h3>
                    <span>Designer</span>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    <ul>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                    </ul>
                </div>

                <div class="single-client">
                    <img src="assets/img/client/client-img-two.jpg" alt="Image">
                    <h3>Juhon Dew</h3>
                    <span>Developer</span>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    <ul>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                    </ul>
                </div>

                <div class="single-client">
                    <img src="assets/img/client/client-img-three.jpg" alt="Image">
                    <h3>Jon Smith</h3>
                    <span>UI UX Designer</span>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    <ul>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                    </ul>
                </div>
                
                <div class="single-client">
                    <img src="assets/img/client/client-img-four.jpg" alt="Image">
                    <h3>Dath Smith Peer</h3>
                    <span>Designer</span>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    <ul>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="shape shape-ome">
        <img src="assets/img/shape/client.png" alt="Image">
    </div>
</section>

<section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Latest From The News</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum suspendisse.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <a routerLink="/news-details" class="d-block text-center"><img src="assets/img/blog/blog-img-one.png" alt="Image"></a>

                    <div class="blog-content-wrap">
                        <ul>
                            <li><a routerLink="/"><i class="flaticon-male"></i> Admin</a></li>
                            <li class="date"><i class="flaticon-appointment"></i> 20 Feb 2020</li>
                        </ul>
                        <h3><a routerLink="/news-details">Web domain hosting the best service work</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolorem.</p>
                        <a class="read-more" routerLink="/news-details">Read More <i class="bx bx-chevron-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <a routerLink="/news-details" class="d-block text-center"><img src="assets/img/blog/blog-img-two.png" alt="Image"></a>

                    <div class="blog-content-wrap">
                        <ul>
                            <li><a routerLink="/"><i class="flaticon-male"></i> Admin</a></li>
                            <li class="date"><i class="flaticon-appointment"></i> 20 Feb 2020</li>
                        </ul>
                        <h3><a routerLink="/news-details">Web development hosting the best service work</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolorem.</p>
                        <a class="read-more" routerLink="/news-details">Read More <i class="bx bx-chevron-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-blog-post">
                    <a routerLink="/news-details" class="d-block text-center"><img src="assets/img/blog/blog-img-three.png" alt="Image"></a>

                    <div class="blog-content-wrap">
                        <ul>
                            <li><a routerLink="/"><i class="flaticon-male"></i> Admin</a></li>
                            <li class="date"><i class="flaticon-appointment"></i> 20 Feb 2020</li>
                        </ul>
                        <h3><a routerLink="/news-details">Web hosting the best service work future</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolorem.</p>
                        <a class="read-more" routerLink="/news-details">Read More <i class="bx bx-chevron-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="contact-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Drop Us A Message</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum suspendisse.</p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <form id="contactForm">
                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <label>Name</label>
                                <input type="text" name="name" class="form-control" placeholder="Your Name">
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <label>Email</label>
                                <input type="email" name="email" class="form-control" placeholder="Your Email">
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <label>Number</label>
                                <input type="text" name="phone-number" class="form-control" placeholder="Your Phone">
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <label>Subject</label>
                                <input type="text" name="msg-subject" class="form-control" placeholder="Your Subject">
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>Comment</label>
                                <textarea name="message" class="form-control" cols="30" rows="5" placeholder="Your Message"></textarea>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <button type="submit" class="default-btn">Send Message</button>
                        </div>
                    </div>
                </form>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="contact-img text-center">
                    <img src="assets/img/contacts-img.png" alt="contacts">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="try-now-free pt-70 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <h3>Complete Hosting Solutions Only $2.95/month</h3>
            </div>

            <div class="col-lg-6 col-md-12 text-end">
                <a class="default-btn" routerLink="/shared-hosting">Try Now For Free</a>
            </div>
        </div>
    </div>
</section>