<footer class="footer-top-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo">
                        <img src="assets/img/white-logo.png" alt="Image">
                    </a>
                    <p>Lorem ipsum dolor consectetur, exercitationem adipisicing elit. Enim, exercitationem. Minima voluptatum.</p>
                    <ul class="social-icon">
                        <!--<li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>-->
                        <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>

            <!--<div class="col-lg-3 col-md-6">
                <div class="single-footer-widget pl-5">
                    <h3>Hosting</h3>

                    <ul>
                        <li><a routerLink="/">Shared Hosting</a></li>
                        <li><a routerLink="/">WordPress Hosting</a></li>
                        <li><a routerLink="/">Reseller Hosting</a></li>
                        <li><a routerLink="/">VPS Hosting</a></li>
                        <li><a routerLink="/">Dedicated Server</a></li>
                    </ul>
                </div>
            </div>-->

            <!--<div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Domains</h3>
                    
                    <ul>
                        <li><a routerLink="/">Domain Name Search</a></li>
                        <li><a routerLink="/">Transfer</a></li>
                        <li><a routerLink="/">Personal Domain</a></li>
                        <li><a routerLink="/">Free DNS</a></li>
                        <li><a routerLink="/">Premium DNS</a></li>
                    </ul>
                </div>
            </div>-->

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Support</h3>
                    
                    <ul>
                        <li><a routerLink="/contact">Contact</a></li>
                        <li><a routerLink="https://devblockcloud.freshdesk.com/support/home">Support</a></li>
                        <!--<li><a routerLink="/">Tutorials</a></li>
                        <li><a routerLink="/">Blog</a></li>
                        <li><a routerLink="/">Premium DNS</a></li>-->
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>

<footer class="footer-bottom-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-4 col-md-6">
                <p>Copyright <i class='bx bx-copyright'></i>2021 <a routerLink="/">DBK Cloud</a>. All rights reserved</p>
            </div>

            <div class="col-lg-4 col-md-6">
                <ul>
                    <li><a routerLink="/terms-conditions">Terms & Conditions</a></li>
                    <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                </ul>
            </div>

        </div>
    </div>
</footer>

<div class="go-top">
    <i class='bx bx-chevrons-up bx-fade-up'></i>
    <i class='bx bx-chevrons-up bx-fade-up'></i>
</div>
