import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { HomeFourComponent } from './components/pages/home-four/home-four.component';
import { HomeFiveComponent } from './components/pages/home-five/home-five.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { AffiliateComponent } from './components/pages/affiliate/affiliate.component';
import { TeamComponent } from './components/pages/team/team.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { LogInComponent } from './components/pages/log-in/log-in.component';
import { SignUpComponent } from './components/pages/sign-up/sign-up.component';
import { RecoverPasswordComponent } from './components/pages/recover-password/recover-password.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { NewsGridComponent } from './components/pages/news-grid/news-grid.component';
import { NewsDetailsComponent } from './components/pages/news-details/news-details.component';
import { NewsRightSidebarComponent } from './components/pages/news-right-sidebar/news-right-sidebar.component';
import { NewsLeftSidebarComponent } from './components/pages/news-left-sidebar/news-left-sidebar.component';
import { DomainComponent } from './components/pages/domain/domain.component';
import { SharedHostingComponent } from './components/pages/shared-hosting/shared-hosting.component';
import { ResellerHostingComponent } from './components/pages/reseller-hosting/reseller-hosting.component';
import { DedicatedHostingComponent } from './components/pages/dedicated-hosting/dedicated-hosting.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ErrorComponent } from './components/pages/error/error.component';

const routes: Routes = [
    {path: '', component: HomeOneComponent},
    {path: 'home-two', component: HomeTwoComponent},
    {path: 'home-three', component: HomeThreeComponent},
    {path: 'home-four', component: HomeFourComponent},
    {path: 'home-five', component: HomeFiveComponent},
    {path: 'about', component: AboutComponent},
    {path: 'services', component: ServicesComponent},
    {path: 'affiliate', component: AffiliateComponent},
    {path: 'team', component: TeamComponent},
    {path: 'faq', component: FaqComponent},
    {path: 'log-in', component: LogInComponent},
    {path: 'sign-up', component: SignUpComponent},
    {path: 'recover-password', component: RecoverPasswordComponent},
    {path: 'terms-conditions', component: TermsConditionsComponent},
    {path: 'privacy-policy', component: PrivacyPolicyComponent},
    {path: 'pricing', component: PricingComponent},
    {path: 'domain', component: DomainComponent},
    {path: 'shared-hosting', component: SharedHostingComponent},
    {path: 'reseller-hosting', component: ResellerHostingComponent},
    {path: 'dedicated-hosting', component: DedicatedHostingComponent},
    {path: 'news-grid', component: NewsGridComponent},
    {path: 'news-left-sidebar', component: NewsLeftSidebarComponent},
    {path: 'news-right-sidebar', component: NewsRightSidebarComponent},
    {path: 'news-details', component: NewsDetailsComponent},
    {path: 'coming-soon', component: ComingSoonComponent},
    {path: 'error', component: ErrorComponent},
    {path: 'contact', component: ContactComponent},
    // Here add new pages component

    {path: '**', component: ErrorComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule {}