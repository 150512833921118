<section class="main-banner-area main-banner-area-two">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="banner-text">
                            <h1>Premium Web Hosting And Domain Services</h1>
                            <p>Up to 50% Off Domain and Hosting, Starting from $2.50/Month Free SSL Certificate, Money-Back Guarantee!</p>

                            <div class="banner-btn">
                                <a class="default-btn" routerLink="/about">Learn More</a>
                                <a class="default-btn active" routerLink="/about">Buy Now</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="banner-main-img banner-one-main-img">
                            <img src="assets/img/banner-two/banner-two-main-img.png" alt="Image">
                        </div>
                        <div class="banner-img">
                            <img class="wow zoomIn" data-wow-delay=".1s" src="assets/img/banner-two/banner-two-shape-one.png" alt="Image">
                            <img class="wow fadeInUp" data-wow-delay=".2s" src="assets/img/banner-two/banner-two-shape-two.png" alt="Image">
                            <img class="wow fadeInUp" data-wow-delay=".4s" src="assets/img/banner-two/banner-two-shape-three.png" alt="Image">
                            <img class="wow fadeInUp" data-wow-delay=".6s" src="assets/img/banner-two/banner-two-shape-four.png" alt="Image">
                            <img class="wow fadeInUp" data-wow-delay=".8s" src="assets/img/banner-two/banner-two-shape-five.png" alt="Image">
                            <img class="wow fadeInUp" data-wow-delay="1s" src="assets/img/banner-two/banner-two-shape-six.png" alt="Image">
                            <img class="wow fadeInUp" data-wow-delay="7s" src="assets/img/banner-two/banner-two-shape-seven.png" alt="Image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-line-one">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-two.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-three.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-two.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-three.png" alt="Image">
    </div>
</section>

<div class="search-domain-area pt-70 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-4 col-md-12">
                <div class="search-text">
                    <h2>It all starts with domain name</h2>
                </div>
            </div>

            <div class="col-lg-8 col-md-12">
                <div class="search-domain">
                    <form class="domain-wrap">
                        <input type="text" class="input-domain" placeholder="Find your domain name here" name="Domain">
                        <button class="default-btn" type="submit" value="submit">Search</button>
                        
                        <select class="select-domain-name">
                            <option>.com</option>
                            <option>.net</option>
                            <option>.info</option>
                            <option>.store</option>
                            <option>.biz</option>
                            <option>.xyz</option>
                            <option>.rog</option>
                        </select>
                    </form>
                    <ul>
                        <li><span>.com</span> $12.99</li>
                        <li><span>.net</span> $10.99</li>
                        <li><span>.info</span> $19.99</li>
                        <li><span>.store</span> $11.99</li>
                        <li><span>.biz</span> $14.99</li>
                        <li><span>.rog</span> $18.99</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Powerful Hosting Services</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum suspendisse.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-technology">
                    <div class="technology-icon-bg">
                        <img src="assets/img/shape/technology-icon-bg.png" alt="Image">
                        <i class="flaticon-uptime"></i>
                    </div>
                    <h3>100% Uptime</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
                    <a class="read-more" routerLink="/shared-hosting">Read More <i class="bx bx-chevron-right"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-technology active">
                    <div class="technology-icon-bg">
                        <img src="assets/img/shape/technology-icon-bg.png" alt="Image">
                        <i class="flaticon-speedometer-1"></i>
                    </div>
                    <h3>Ultimate Speed</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
                    <a class="read-more" routerLink="/shared-hosting">Read More <i class="bx bx-chevron-right"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="single-technology">
                    <div class="technology-icon-bg">
                        <img src="assets/img/shape/technology-icon-bg.png" alt="Image">
                        <i class="flaticon-database-1"></i>
                    </div>
                    <h3>Money-Back Guarantee</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
                    <a class="read-more" routerLink="/shared-hosting">Read More <i class="bx bx-chevron-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="customer-support-area pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="support-img support-img-two">
                    <img src="assets/img/support-img-two.png" alt="Image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="customer-title">
                    <h2>24/7 Online Customer Support</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt  labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commo o viverra maecenas accumsan lacus.</p>
                </div>
                <ul class="support-list mb-0">
                    <li>Next to the hosting account, you want to use, click Manage</li>
                    <li>Select or enter the domain name you want to use</li>
                    <li>After you purchase a hosting plan</li>
                    <li>Why choose wohust hosting</li>
                </ul>	
            </div>
        </div>
    </div>
</section>

<section class="choose-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="choose-content-wrap">
                    <h2>Dedicated Server Features</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel.</p>

                    <ul>
                        <li>
                            <div class="choose-icon">
                                <i class="flaticon-customer-service"></i>
                            </div>
                            <span>Online Support</span>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </li>
                        <li>
                            <div class="choose-icon">
                                <i class="flaticon-speedometer-1"></i>
                            </div>
                            <span>Fast Performance</span>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </li>
                        <li>
                            <div class="choose-icon">
                                <i class="flaticon-molecule"></i>
                            </div>
                            <span>Multiple Network</span>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="choose-img">
                    <img src="assets/img/choose-img.png" alt="Image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="pricing-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Choose The Best Hosting Plan</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum suspendisse.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-pricing">
                    <div class="pricing-top-heading">
                        <h3>Basic</h3>
                        <p>Build a website</p>
                    </div>
                    <span>$29.00 <sub>/Month</sub></span>

                    <ul>
                        <li><i class="flaticon-tick"></i> 10GB Storage Space</li>
                        <li><i class="flaticon-tick"></i> 50GB Bandwidth</li>
                        <li><i class="flaticon-tick"></i> 10 Free Sub-Domains</li>
                        <li><i class="flaticon-tick"></i> 10GB Storage Space</li>
                        <li><i class="flaticon-tick"></i> 50GB Bandwidth</li>
                        <li><i class="flaticon-tick"></i> 10 Free Sub-Domains</li>
                    </ul>
                    <a class="default-btn" routerLink="/">Get Started</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-pricing">
                    <div class="pricing-top-heading">
                        <h3>Comfort</h3>
                        <p>Build a website</p>
                    </div>
                    <span>$59.00 <sub>/Month</sub></span>

                    <ul>
                        <li><i class="flaticon-tick"></i> 15GB Storage Space</li>
                        <li><i class="flaticon-tick"></i> 50GB Bandwidth</li>
                        <li><i class="flaticon-tick"></i> 10 Free Sub-Domains</li>
                        <li><i class="flaticon-tick"></i> 15GB Storage Space</li>
                        <li><i class="flaticon-tick"></i> 100GB Bandwidth</li>
                        <li><i class="flaticon-tick"></i> 50 Free Sub-Domains</li>
                    </ul>
                    <a class="default-btn" routerLink="/">Get Started</a>
                </div>
                <span class="popular">Popular</span>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-pricing">
                    <div class="pricing-top-heading">
                        <h3>Premium</h3>
                        <p>Build a website</p>
                    </div>
                    <span>$79.00 <sub>/Month</sub></span>

                    <ul>
                        <li><i class="flaticon-tick"></i> 20GB Storage Space</li>
                        <li><i class="flaticon-tick"></i> 50GB Bandwidth</li>
                        <li><i class="flaticon-tick"></i> 10 Free Sub-Domains</li>
                        <li><i class="flaticon-tick"></i> 420GB Storage Space</li>
                        <li><i class="flaticon-tick"></i> 50GB Bandwidth</li>
                        <li><i class="flaticon-tick"></i> 50 Free Sub-Domains</li>
                    </ul>
                    <a class="default-btn" routerLink="/">Get Started</a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="location-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Data Center Locations</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum suspendisse.</p>
        </div>

        <div class="map-img">
            <img src="assets/img/map-img.png" alt="Image">

            <div class="single-location video-btn">
                <i class="bx bx-location-plus"></i>
                <span>Russia</span>
            </div>

            <div class="single-location location-two video-btn">
                <i class="bx bx-location-plus"></i>
                <span>London</span>
            </div>

            <div class="single-location location-three video-btn">
                <i class="bx bx-location-plus"></i>
                <span>Canada</span>
            </div>

            <div class="single-location location-four video-btn">
                <i class="bx bx-location-plus"></i>
                <span>Brazil</span>
            </div>

            <div class="single-location location-five video-btn">
                <i class="bx bx-location-plus"></i>
                <span>Australia</span>
            </div>

            <div class="single-location location-six video-btn">
                <i class="bx bx-location-plus"></i>
                <span>Washington</span>
            </div>
        </div>

        <ul class="by-domain">
            <li><span>Russia</span> (50)</li>
            <li><span>London</span> (90)</li>
            <li><span>Canada</span> (80)</li>
            <li><span>Brazil</span> (40)</li>
            <li><span>Australia</span> (60)</li>
            <li><span>Washington</span> (70)</li>
        </ul>
    </div>
</section>

<section class="solutions-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="solutions-img">
                    <img src="assets/img/solutions-img.png" alt="Image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="solutions-wrap">
                    <div class="solutions-title">
                        <h2>Complete Cloud Hosting Solutions</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt  labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commo o viverra maecenas accumsan lacus.</p>
                    </div>

                    <ul class="solutions-list">
                        <li><i class="flaticon-right"></i> Next to the hosting account, you want to use, click Manage</li>
                        <li><i class="flaticon-right"></i> Select or enter the domain name you want to use</li>
                        <li><i class="flaticon-right"></i> After you purchase a hosting plan</li>
                    </ul>

                    <a class="default-btn" routerLink="/#">Get The Service</a>	
                </div>
            </div>
        </div>
    </div>
</section>

<section class="hosting-solution-area ptb-100">
    <div class="container">
        <div class="hosting-solution-wrap">
            <h2>We Don't Compromise With The Best Hosting Solution</h2>
            <a class="default-btn" routerLink="/shared-hosting">Get Started</a>
            <a class="default-btn active" routerLink="/contact">Contact Us</a>
        </div>
    </div>

    <div class="shape shape-one"><img src="assets/img/shape/hosting-solution-one.png" alt=""></div>
    <div class="shape shape-two"><img src="assets/img/shape/hosting-solution-two.png" alt=""></div>
</section>

<section class="solutions-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="solutions-wrap">
                    <div class="solutions-title">
                        <h2>Complete Cloud Hosting Solutions</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt  labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commo o viverra maecenas accumsan lacus.</p>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia quasi nam, dolor architecto, dicta placeat facere quae quam culpa omnis aut praesentium magni eos unde quod sed? Non, fugiat totam?</p>
                    </div>

                    <a class="default-btn" routerLink="/">Know More</a>	
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="protected-img">
                    <img src="assets/img/protected-img.png" alt="Image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="protected-area pb-100">
    <div class="container">
        <div class="section-title">
            <h2>Stay Protected Advanced Firewall</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum suspendisse.</p>
        </div>
        
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="frequently-content-wrap">
                    <ul>
                        <li>
                            <i class="flaticon-database-1"></i>
                            <h3>What is wohust hosting</h3>
                            <p>Lorem ipsum consectetur dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun.</p>
                        </li>
                        <li>
                            <i class="flaticon-diagram"></i>
                            <h3>Why choose wohust hosting</h3>
                            <p>Lorem ipsum consectetur dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun.</p>
                        </li>
                        <li>
                            <i class="flaticon-uptime"></i>
                            <h3>If this an affiliate program?</h3>
                            <p>Lorem ipsum consectetur dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun.</p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="frequently-content-wrap">
                    <ul>
                        <li>
                            <i class="flaticon-migrating"></i>
                            <h3>Award-wining support</h3>
                            <p>Lorem ipsum consectetur dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun.</p>
                        </li>
                        <li>
                            <i class="flaticon-network-1"></i>
                            <h3>The visoin for our mision</h3>
                            <p>Lorem ipsum consectetur dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun.</p>
                        </li>
                        <li>
                            <i class="flaticon-database"></i>
                            <h3>All in one place</h3>
                            <p>Lorem ipsum consectetur dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="client-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>What Our Client Say</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum suspendisse.</p>
        </div>

        <div class="row">
            <div class="client-wrap owl-carousel owl-theme">
                <div class="single-client">
                    <img src="assets/img/client/client-img-one.jpg" alt="Image">
                    <h3>Denial Peer</h3>
                    <span>Designer</span>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    <ul>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                    </ul>
                </div>

                <div class="single-client">
                    <img src="assets/img/client/client-img-two.jpg" alt="Image">
                    <h3>Juhon Dew</h3>
                    <span>Developer</span>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    <ul>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                    </ul>
                </div>

                <div class="single-client">
                    <img src="assets/img/client/client-img-three.jpg" alt="Image">
                    <h3>Jon Smith</h3>
                    <span>UI UX Designer</span>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    <ul>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                    </ul>
                </div>
                
                <div class="single-client">
                    <img src="assets/img/client/client-img-four.jpg" alt="Image">
                    <h3>Dath Smith Peer</h3>
                    <span>Designer</span>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    <ul>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="shape shape-ome">
        <img src="assets/img/shape/client.png" alt="Image">
    </div>
</section>

<section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Latest From The News</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum suspendisse.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <a routerLink="/news-details" class="d-block text-center"><img src="assets/img/blog/blog-img-one.png" alt="Image"></a>

                    <div class="blog-content-wrap">
                        <ul>
                            <li><a routerLink="/"><i class="flaticon-male"></i> Admin</a></li>
                            <li class="date"><i class="flaticon-appointment"></i> 20 Feb 2020</li>
                        </ul>
                        <h3><a routerLink="/news-details">Web domain hosting the best service work</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolorem.</p>
                        <a class="read-more" routerLink="/news-details">Read More <i class="bx bx-chevron-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <a routerLink="/news-details" class="d-block text-center"><img src="assets/img/blog/blog-img-two.png" alt="Image"></a>

                    <div class="blog-content-wrap">
                        <ul>
                            <li><a routerLink="/"><i class="flaticon-male"></i> Admin</a></li>
                            <li class="date"><i class="flaticon-appointment"></i> 20 Feb 2020</li>
                        </ul>
                        <h3><a routerLink="/news-details">Web development hosting the best service work</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolorem.</p>
                        <a class="read-more" routerLink="/news-details">Read More <i class="bx bx-chevron-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-blog-post">
                    <a routerLink="/news-details" class="d-block text-center"><img src="assets/img/blog/blog-img-three.png" alt="Image"></a>

                    <div class="blog-content-wrap">
                        <ul>
                            <li><a routerLink="/"><i class="flaticon-male"></i> Admin</a></li>
                            <li class="date"><i class="flaticon-appointment"></i> 20 Feb 2020</li>
                        </ul>
                        <h3><a routerLink="/news-details">Web hosting the best service work future</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolorem.</p>
                        <a class="read-more" routerLink="/news-details">Read More <i class="bx bx-chevron-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="try-now-free pt-70 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <h3>Complete Hosting Solutions Only $2.95/month</h3>
            </div>

            <div class="col-lg-6 col-md-12 text-end">
                <a class="default-btn" routerLink="/shared-hosting">Try Now For Free</a>
            </div>
        </div>
    </div>
</section>