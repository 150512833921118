<section class="main-banner-area main-banner-area-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="banner-text">
                            <h1>Explore Over World With Powerful  Hosting</h1>
                            <p>Up to 50% Off Domain and Hosting, Starting from $2.50/Month Free SSL Certificate, Money-Back Guarantee!</p>
                            
                            <div class="banner-btn">
                                <a class="default-btn" routerLink="/about">Learn More</a>
                                <a class="default-btn active" routerLink="/shared-hosting">Buy Now</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="banner-img">
                            <img class="banner-img-part-two wow fadeInDown" data-wow-delay=".2s" src="assets/img/banner-four/banner-img-two.png" alt="">
                            <div class="banner-img-part-one wow fadeInDown" data-wow-delay=".5s">
                                <img src="assets/img/banner-four/banner-img-one.png" alt="Image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-line-one">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-two.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-three.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-two.png" alt="Image">
        <img src="assets/img/banner-shape/banner-shape-three.png" alt="Image">
    </div>
</section>

<div class="search-domain-area search-domain-area-four">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-4 col-md-12">
                <div class="search-text">
                    <h2>It all starts with domain name</h2>
                </div>
            </div>

            <div class="col-lg-8 col-md-12">
                <div class="search-domain">
                    <form class="domain-wrap">
                        <input type="text" class="input-domain" placeholder="Find your domain name here" name="Domain">
                        <button class="default-btn" type="submit" value="submit">Search</button>
                        
                        <select class="select-domain-name">
                            <option>.com</option>
                            <option>.net</option>
                            <option>.info</option>
                            <option>.store</option>
                            <option>.biz</option>
                            <option>.xyz</option>
                        </select>
                    </form>
                    <ul>
                        <li><span>.com</span> $12.99</li>
                        <li><span>.net</span> $10.99</li>
                        <li><span>.info</span> $19.99</li>
                        <li><span>.store</span> $11.99</li>
                        <li><span>.biz</span> $14.99</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Powerful Hosting Services</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum suspendisse.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-technology">
                    <div class="technology-icon-bg">
                        <img src="assets/img/shape/technology-icon-bg.png" alt="Image">
                        <i class="flaticon-uptime"></i>
                    </div>
                    <h3>100% Uptime</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
                    <a class="read-more" routerLink="/shared-hosting">Read More <i class="bx bx-chevron-right"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-technology active">
                    <div class="technology-icon-bg">
                        <img src="assets/img/shape/technology-icon-bg.png" alt="Image">
                        <i class="flaticon-speedometer-1"></i>
                    </div>
                    <h3>Ultimate Speed</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
                    <a class="read-more" routerLink="/shared-hosting">Read More <i class="bx bx-chevron-right"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="single-technology">
                    <div class="technology-icon-bg">
                        <img src="assets/img/shape/technology-icon-bg.png" alt="Image">
                        <i class="flaticon-database-1"></i>
                    </div>
                    <h3>Money-Back Guarantee</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
                    <a class="read-more" routerLink="/shared-hosting">Read More <i class="bx bx-chevron-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="customer-support-area pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="customer-title">
                    <h2>24/7 Online Customer Support</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt  labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commo o viverra maecenas accumsan lacus.</p>
                </div>

                <ul class="support-list">
                    <li>Next to the hosting account, you want to use, click Manage</li>
                    <li>Select or enter the domain name you want to use</li>
                    <li>After you purchase a hosting plan</li>
                    <li>Why choose wohust hosting</li>
                </ul>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="support-img">
                    <img src="assets/img/support-img.png" alt="Image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="hosting-services-area pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Hosta Hosting Services</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum suspendisse.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-hosting-service">
                    <i class="flaticon-settings"></i>
                    <h3>Storage & Backup</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-hosting-service">
                    <i class="flaticon-network"></i>
                    <h3>Easy Share</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-hosting-service">
                    <i class="flaticon-diagram"></i>
                    <h3>Cloud Hosting</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-hosting-service">
                    <i class="flaticon-backup"></i>
                    <h3>Domain Registration</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-hosting-service">
                    <i class="flaticon-database-1"></i>
                    <h3>Free SSL Certificate</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-hosting-service">
                    <i class="flaticon-network"></i>
                    <h3>Cloud Hosting</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="pricing-area pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Choose The Best Hosting Plan</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum suspendisse.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-pricing">
                    <div class="pricing-top-heading">
                        <h3>Basic</h3>
                        <p>Build a website</p>
                    </div>
                    <span>$29.00 <sub>/Month</sub></span>

                    <ul>
                        <li><i class="flaticon-tick"></i> 10GB Storage Space</li>
                        <li><i class="flaticon-tick"></i> 50GB Bandwidth</li>
                        <li><i class="flaticon-tick"></i> 10 Free Sub-Domains</li>
                        <li><i class="flaticon-tick"></i> 10GB Storage Space</li>
                        <li><i class="flaticon-tick"></i> 50GB Bandwidth</li>
                        <li><i class="flaticon-tick"></i> 10 Free Sub-Domains</li>
                    </ul>
                    <a class="default-btn" routerLink="/">Get Started</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-pricing">
                    <div class="pricing-top-heading">
                        <h3>Comfort</h3>
                        <p>Build a website</p>
                    </div>
                    <span>$59.00 <sub>/Month</sub></span>

                    <ul>
                        <li><i class="flaticon-tick"></i> 15GB Storage Space</li>
                        <li><i class="flaticon-tick"></i> 50GB Bandwidth</li>
                        <li><i class="flaticon-tick"></i> 10 Free Sub-Domains</li>
                        <li><i class="flaticon-tick"></i> 15GB Storage Space</li>
                        <li><i class="flaticon-tick"></i> 100GB Bandwidth</li>
                        <li><i class="flaticon-tick"></i> 50 Free Sub-Domains</li>
                    </ul>
                    <a class="default-btn" routerLink="/">Get Started</a>
                </div>
                <span class="popular">Popular</span>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-pricing">
                    <div class="pricing-top-heading">
                        <h3>Premium</h3>
                        <p>Build a website</p>
                    </div>
                    <span>$79.00 <sub>/Month</sub></span>

                    <ul>
                        <li><i class="flaticon-tick"></i> 20GB Storage Space</li>
                        <li><i class="flaticon-tick"></i> 50GB Bandwidth</li>
                        <li><i class="flaticon-tick"></i> 10 Free Sub-Domains</li>
                        <li><i class="flaticon-tick"></i> 420GB Storage Space</li>
                        <li><i class="flaticon-tick"></i> 50GB Bandwidth</li>
                        <li><i class="flaticon-tick"></i> 50 Free Sub-Domains</li>
                    </ul>
                    <a class="default-btn" routerLink="/">Get Started</a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="solutions-area pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="solutions-img">
                    <img src="assets/img/solutions-img.png" alt="Image">
                </div>
            </div>
            
            <div class="col-lg-6 col-md-12">
                <div class="solutions-wrap">
                    <div class="solutions-title">
                        <h2>Complete Cloud Hosting Solutions</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt  labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commo o viverra maecenas accumsan lacus.</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <ul class="solutions-list">
                                <li><i class="flaticon-right"></i> Open source and free</li>
                                <li><i class="flaticon-right"></i> Advanced SEO and social tools</li>
                                <li><i class="flaticon-right"></i> A wide database's template</li>
                            </ul>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <ul class="solutions-list">
                                <li><i class="flaticon-right"></i> Customized design assistance</li>
                                <li><i class="flaticon-right"></i> Prioritizing simplicity</li>
                                <li><i class="flaticon-right"></i> Plans for all customer need </li>
                            </ul>
                        </div>
                    </div>
                    
                    <a class="default-btn" routerLink="/domain">Search Domain</a>	
                    <a class="default-btn ml-2" routerLink="/domain">Transfer</a>	
                </div>
            </div>
        </div>
    </div>
</section>

<section class="our-team-area pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Meet Our Team</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum suspendisse.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <img src="assets/img/team/team-bg.png" alt="Image">

                    <div class="team-man">
                        <img src="assets/img/team/team-img-one.png" alt="Image">
                    </div>

                    <h3>Jenifar Ikra</h3>
                    <span>Head of Idae</span>

                    <ul class="social-icon">
                        <li><a href="#"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="#"><i class="bx bxl-instagram"></i></a></li>
                        <li><a href="#"><i class="bx bxl-linkedin"></i></a></li>
                        <li><a href="#"><i class="bx bxl-youtube"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <img src="assets/img/team/team-bg.png" alt="Image">

                    <div class="team-man">
                        <img src="assets/img/team/team-img-two.png" alt="Image">
                    </div>

                    <h3>Jenia Popy</h3>
                    <span>UI/UX Designer</span>

                    <ul class="social-icon">
                        <li><a href="#"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="#"><i class="bx bxl-instagram"></i></a></li>
                        <li><a href="#"><i class="bx bxl-linkedin"></i></a></li>
                        <li><a href="#"><i class="bx bxl-youtube"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-team">
                    
                    <img src="assets/img/team/team-bg.png" alt="Image">
                    <div class="team-man">
                        <img src="assets/img/team/team-img-three.png" alt="Image">
                    </div>

                    <h3>Alisa Merina</h3>
                    <span>Full Stack Dev</span>

                    <ul class="social-icon">
                        <li><a href="#"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="#"><i class="bx bxl-instagram"></i></a></li>
                        <li><a href="#"><i class="bx bxl-linkedin"></i></a></li>
                        <li><a href="#"><i class="bx bxl-youtube"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="location-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Data Center Locations</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum suspendisse.</p>
        </div>

        <div class="map-img">
            <img src="assets/img/map-img.png" alt="Image">

            <div class="single-location video-btn">
                <i class="bx bx-location-plus"></i>
                <span>Russia</span>
            </div>

            <div class="single-location location-two video-btn">
                <i class="bx bx-location-plus"></i>
                <span>London</span>
            </div>

            <div class="single-location location-three video-btn">
                <i class="bx bx-location-plus"></i>
                <span>Canada</span>
            </div>

            <div class="single-location location-four video-btn">
                <i class="bx bx-location-plus"></i>
                <span>Brazil</span>
            </div>

            <div class="single-location location-five video-btn">
                <i class="bx bx-location-plus"></i>
                <span>Australia</span>
            </div>

            <div class="single-location location-six video-btn">
                <i class="bx bx-location-plus"></i>
                <span>Washington</span>
            </div>
        </div>

        <ul class="by-domain">
            <li><span>Russia</span> (50)</li>
            <li><span>London</span> (90)</li>
            <li><span>Canada</span> (80)</li>
            <li><span>Brazil</span> (40)</li>
            <li><span>Australia</span> (60)</li>
            <li><span>Washington</span> (70)</li>
        </ul>
    </div>
</section>

<section class="shared-hosting-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-6">
                <ul class="hosting-list">
                    <li><a routerLink="/shared-hosting">Shared Hosting</a></li>

                    <li><a routerLink="/reseller-hosting">Reseller Hosting</a></li>

                    <li><a routerLink="/dedicated-hosting">Dedicated Hosting</a></li>

                    <li><a routerLink="/shared-hosting">VPS Hosting</a></li>
                </ul>
            </div>

            <div class="col-lg-5 col-md-6">
                <div class="shared-hosting-content">
                    <h2>Shared Hosting</h2>
                    <p>Lorem ipsum dolor sit amet, labore et dolore magna consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum sgravida. Risus.</p>
                </div>

                <ul class="shared-hosting-list">
                    <li><i class="flaticon-tick"></i><span>After you purchase a hosting plan</span></li>

                    <li><i class="flaticon-tick"></i><span>Next to the hosting account, you want to Manage</span></li>

                    <li><i class="flaticon-tick"></i><span>Select or enter the domain name you want to use</span></li>

                    <li><i class="flaticon-tick"></i><span>A wide database's template</span></li>

                    <li><i class="flaticon-tick"></i><span>Customized design assistance</span></li>
                </ul>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="shared-hosting-img">
                    <img src="assets/img/shared-hosting-img.png" alt="Image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="client-area client-area-four pt-100">
    <div class="container">
        <div class="section-title">
            <h2>What Our Client Say</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum suspendisse.</p>
        </div>

        <div class="row">
            <div class="client-wrap-two owl-carousel owl-theme">
                <div class="single-client">
                    <img src="assets/img/client/client-img-one.jpg" alt="Image">
                    <h3>Denial Peer</h3>
                    <span>Designer</span>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>

                    <ul>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                    </ul>
                </div>

                <div class="single-client">
                    <img src="assets/img/client/client-img-two.jpg" alt="Image">
                    <h3>Juhon Dew</h3>
                    <span>Developer</span>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    
                    <ul>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                    </ul>
                </div>

                <div class="single-client">
                    <img src="assets/img/client/client-img-three.jpg" alt="Image">
                    <h3>Jon Smith</h3>
                    <span>UI UX Designer</span>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    
                    <ul>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                    </ul>
                </div>

                <div class="single-client">
                    <img src="assets/img/client/client-img-four.jpg" alt="Image">
                    <h3>Dath Smith Peer</h3>
                    <span>Designer</span>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    
                    <ul>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="shape shape-ome"><img src="assets/img/shape/client.png" alt="Image"></div>
</section>

<section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Latest From The News</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum suspendisse.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <a routerLink="/news-details" class="d-block text-center"><img src="assets/img/blog/blog-img-one.png" alt="Image"></a>

                    <div class="blog-content-wrap">
                        <ul>
                            <li><a routerLink="/"><i class="flaticon-male"></i> Admin</a></li>
                            <li class="date"><i class="flaticon-appointment"></i> 20 Feb 2020</li>
                        </ul>
                        <h3><a routerLink="/news-details">Web domain hosting the best service work</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolorem.</p>
                        <a class="read-more" routerLink="/news-details">Read More <i class="bx bx-chevron-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <a routerLink="/news-details" class="d-block text-center"><img src="assets/img/blog/blog-img-two.png" alt="Image"></a>

                    <div class="blog-content-wrap">
                        <ul>
                            <li><a routerLink="/"><i class="flaticon-male"></i> Admin</a></li>
                            <li class="date"><i class="flaticon-appointment"></i> 20 Feb 2020</li>
                        </ul>
                        <h3><a routerLink="/news-details">Web development hosting the best service work</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolorem.</p>
                        <a class="read-more" routerLink="/news-details">Read More <i class="bx bx-chevron-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-blog-post">
                    <a routerLink="/news-details" class="d-block text-center"><img src="assets/img/blog/blog-img-three.png" alt="Image"></a>

                    <div class="blog-content-wrap">
                        <ul>
                            <li><a routerLink="/"><i class="flaticon-male"></i> Admin</a></li>
                            <li class="date"><i class="flaticon-appointment"></i> 20 Feb 2020</li>
                        </ul>
                        <h3><a routerLink="/news-details">Web hosting the best service work future</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolorem.</p>
                        <a class="read-more" routerLink="/news-details">Read More <i class="bx bx-chevron-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="brand-area pb-70">
    <div class="container">
        <div class="row">
            <div class="brand-wrap owl-carousel owl-theme">
                <div class="single-brand">
                    <img src="assets/img/brand-logo/brand-logo-one.png" alt="Image">
                </div>

                <div class="single-brand">
                    <img src="assets/img/brand-logo/brand-logo-two.png" alt="Image">
                </div>

                <div class="single-brand">
                    <img src="assets/img/brand-logo/brand-logo-three.png" alt="Image">
                </div>

                <div class="single-brand">
                    <img src="assets/img/brand-logo/brand-logo-four.png" alt="Image">
                </div>

                <div class="single-brand">
                    <img src="assets/img/brand-logo/brand-logo-five.png" alt="Image">
                </div>
            </div>
        </div>
    </div>
</div>

<section class="contact-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Drop Us A Message</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum suspendisse.</p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <form id="contactForm">
                    <div class="row">
                        <div class="col-lg-12 col-sm-6">
                            <div class="form-group">
                                <input type="text" name="name" class="form-control" placeholder="Your Name">
                            </div>
                        </div>

                        <div class="col-lg-12 col-sm-6">
                            <div class="form-group">
                                <input type="email" name="email" class="form-control" placeholder="Your Email">
                            </div>
                        </div>

                        <div class="col-lg-12 col-sm-6">
                            <div class="form-group">
                                <input type="text" name="phone-number" class="form-control" placeholder="Your Phone">
                            </div>
                        </div>

                        <div class="col-lg-12 col-sm-6">
                            <div class="form-group">
                                <input type="text" name="msg-subject" class="form-control" placeholder="Your Subject">
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <textarea name="message" class="form-control" cols="30" rows="5" placeholder="Your Message"></textarea>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <button type="submit" class="default-btn">Send Message</button>
                        </div>
                    </div>
                </form>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="contact-img text-center">
                    <img src="assets/img/contacts-img.png" alt="contacts">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="try-now-free pt-70 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <h3>Complete Hosting Solutions Only $2.95/month</h3>
            </div>

            <div class="col-lg-6 col-md-12 text-end">
                <a class="default-btn" routerLink="/shared-hosting">Try Now For Free</a>
            </div>
        </div>
    </div>
</section>