<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <h2>Privacy Policy</h2>
                </div>


            </div>
        </div>
    </div>
</div>

<section class="terms-conditions ptb-100">
    <div class="container">
        <div class="single-privacy">

            <h3 class="mt-0">
                Privacy Policy
            </h3>

            <p>
                Protecting your private information is our priority. This Statement of Privacy applies to www.devblockcloud.com and DevBlock Cloud and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to DevBlock Cloud include www.devblockcloud.com and DBC. The DBC website is a Cloud Services site. By using the DBC website, you consent to the data practices described in this statement.
            </p>
            <h3 class="mt-0">
                Collection of your Personal Information
            </h3>
            <p>
                In order to better provide you with products and services offered on our Site, DBC may collect personally identifiable information, such as your:
            </p>
            -	First and Last Name
            -	Mailing Address
            -	E-mail Address
            -	Phone Number
            -	Employer
            -	Job Title

            <p>
                We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be required to provide certain personal information to us when you elect to use certain products or services available on the Site. These may include: (a) registering for an account on our Site; (b) entering a sweepstakes or contest sponsored by us or one of our partners; (c) signing up for special offers from selected third parties; (d) sending us an email message; (e) submitting your credit card or other payment information when ordering and purchasing products and services on our Site. To wit, we will use your information for, but not limited to, communicating with you in relation to services and/or products you have requested from us. We also may gather additional personal or non-personal information in the future.
            </p>
            <h3 class="mt-0">
                Use of your Personal Information
            </h3>
            <p>
                DBC collects and uses your personal information to operate its website(s) and deliver the services you have requested.

                DBC may also use your personally identifiable information to inform you of other products or services available from DBC and its affiliates.
            </p>
            <h3 class="mt-0">
                Sharing Information with Third Parties
            </h3>
            <p>
                DBC does not sell, rent or lease its customer lists to third parties.

                DBC may, from time to time, contact you on behalf of external business partners about a particular offering that may be of interest to you. In those cases, your unique personally identifiable information (e-mail, name, address, telephone number) is transferred to the third party. DBC may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to DBC, and they are required to maintain the confidentiality of your information.

                DBC may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on DBC or the site; (b) protect and defend the rights or property of DBC; and/or (c) act under exigent circumstances to protect the personal safety of users of DBC, or the public.
            </p>
            <h3 class="mt-0">
                Tracking User Behavior
            </h3>
            <p>
                DBC may keep track of the websites and pages our users visit within DBC, in order to determine what DBC services are the most popular. This data is used to deliver customized content and advertising within DBC to customers whose behavior indicates that they are interested in a particular subject area.
            </p>
            <h3 class="mt-0">
                Automatically Collected Information
            </h3>
            <p>
                Information about your computer hardware and software may be automatically collected by DBC. This information can include: your IP address, browser type, domain names, access times and referring website addresses. This information is used for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the DBC website.
            </p>
            <h3 class="mt-0">
                Use of Cookies
            </h3>
            <p>
                The DBC website may use "cookies" to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you.

                One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the Web server that you have returned to a specific page. For example, if you personalize DBC pages, or register with DBC site or services, a cookie helps DBC to recall your specific information on subsequent visits. This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on. When you return to the same DBC website, the information you previously provided can be retrieved, so you can easily use the DBC features that you customized.

                You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the DBC services or websites you visit.
            </p>
            <h3 class="mt-0">
                Links
            </h3>
            <p>
                This website contains links to other sites. Please be aware that we are not responsible for the content or privacy practices of such other sites. We encourage our users to be aware when they leave our site and to read the privacy statements of any other site that collects personally identifiable information.
            </p>
            <h3 class="mt-0">
                Security of your Personal Information
            </h3>
            <p>
                DBC secures your personal information from unauthorized access, use, or disclosure. DBC uses the following methods for this purpose:

                -	SSL Protocol

                When personal information (such as a credit card number) is transmitted to other websites, it is protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol.

                We strive to take appropriate security measures to protect against unauthorized access to or alteration of your personal information. Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your personal information, you acknowledge that: (a) there are security and privacy limitations inherent to the Internet which are beyond our control; and (b) security, integrity, and privacy of any and all information and data exchanged between you and us through this Site cannot be guaranteed.
            </p>
            <h3 class="mt-0">
                Children Under Thirteen
            </h3>
            <p>
                DBC does not knowingly collect personally identifiable information from children under the age of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use this website.

                Opt-Out & Unsubscribe from Third Party Communications
                We respect your privacy and give you an opportunity to opt-out of receiving announcements of certain information. Users may opt-out of receiving any or all communications from third-party partners of DBC by contacting us here:
                - Web page: _________________
                - Email: optout@devblockcloud.com
                - Phone: _________________
            </p>
            <h3 class="mt-0">
                E-mail Communications
            </h3>
            <p>
                From time to time, DBC may contact you via email for the purpose of providing announcements, promotional offers, alerts, confirmations, surveys, and/or other general communication. In order to improve our Services, we may receive a notification when you open an email from DBC or click on a link therein.

                If you would like to stop receiving marketing or promotional communications via email from DBC, you may opt out of such communications by Use the unsubscribe link on the email.
            </p>
            <h3 class="mt-0">
                External Data Storage Sites
            </h3>
            <p>
                We may store your data on servers provided by third party hosting vendors with whom we have contracted.
            </p>
            <h3 class="mt-0">
                Changes to this Statement
            </h3>
            <p>
                DBC reserves the right to change this Privacy Policy from time to time. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account, by placing a prominent notice on our site, and/or by updating any privacy information on this page. Your continued use of the Site and/or Services available through this Site after such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy.
            </p>
            <h3 class="mt-0">
                Contact Information
            </h3>
            <p>
                DBC welcomes your questions or comments regarding this Statement of Privacy. If you believe that DBC has not adhered to this Statement, please contact DBC at:

                DevBlock Cloud
                535 E Fernhurst Dr, Katy, TX 77450
                Katy, Texas 77494

                Email Address:
                privacy@devblockcloud.com
            </p>
        </div>
    </div>
</section>
