<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <h2>Articles</h2>
                    <p>bits of free relevant knowlage </p>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <a href="https://www.linkedin.com/pulse/tagging-cost-management-cloud-engineering-chris-cabezudo/" class="d-block text-center">
                        <img src="https://media-exp1.licdn.com/dms/image/C4E12AQEj99A6G1wzeg/article-cover_image-shrink_600_2000/0/1627419141181?e=1654732800&v=beta&t=7mmvoMNhgR97xV-MoPKldg81VvYGqndzYNS3SHVGfSs" alt="Image">
                    </a>

                    <div class="blog-content-wrap">
                        <ul>
                            <li><a routerLink="/"><i class="flaticon-male"></i> Chris C2</a></li>
                            <li class="date"><i class="flaticon-appointment"></i> 20 Feb 2020</li>
                        </ul>
                        <h3><a rhref="https://www.linkedin.com/pulse/tagging-cost-management-cloud-engineering-chris-cabezudo/">Tagging - Cost Management for Cloud Engineering</a></h3>
                        <p>In this article, I want to expand on Microsoft Tagging recommendations and focus on software development teams.</p>
                        <a class="read-more" href="https://www.linkedin.com/pulse/tagging-cost-management-cloud-engineering-chris-cabezudo/">Read More <i class="bx bx-chevron-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <a href="https://www.linkedin.com/pulse/getting-cloud-chris-cabezudo/" class="d-block text-center">
                        <img src="https://media-exp1.licdn.com/dms/image/C4E12AQEL3Jw3XJHctA/article-cover_image-shrink_720_1280/0/1579909312019?e=2147483647&v=beta&t=-tz1xR-fcclUh6s1gl3Q3_-8kXUEX8f_xdpw5InH5Ik" alt="Image">
                    </a>

                    <div class="blog-content-wrap">
                        <ul>
                            <li><a routerLink="/"><i class="flaticon-male"></i> Chris C2</a></li>
                            <li class="date"><i class="flaticon-appointment"></i> Jul 27, 2021</li>
                        </ul>
                        <h3><a href="https://www.linkedin.com/pulse/getting-cloud-chris-cabezudo/">Getting to the cloud</a></h3>
                        <p>Most teams, don't have a dedicated Azure Engineer or Cloud Architect. This job is taken by the developers eager to learn and use this new thing everyone is talking about.</p>
                        <a class="read-more" href="https://www.linkedin.com/pulse/getting-cloud-chris-cabezudo/">Read More <i class="bx bx-chevron-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-blog-post">
                    <a href="https://www.linkedin.com/pulse/conquer-your-office-365-bill-reduce-overall-cost-chris-cabezudo?trk=pulse-article_more-articles_related-content-card" class="d-block text-center">
                        <img src="https://media-exp1.licdn.com/dms/image/C4E12AQFBOF9kCLkOzA/article-cover_image-shrink_720_1280/0/1578438291708?e=2147483647&v=beta&t=4jGg9JRiGln81WKcvyoTGLDrvbflQ1ANm0UKeOdSbeY" alt="Image">
                    </a>

                    <div class="blog-content-wrap">
                        <ul>
                            <li><a routerLink="/"><i class="flaticon-male"></i> Chris C2</a></li>
                            <li class="date"><i class="flaticon-appointment"></i> Jan 7, 2020</li>
                        </ul>
                        <h3><a href="https://www.linkedin.com/pulse/conquer-your-office-365-bill-reduce-overall-cost-chris-cabezudo?trk=pulse-article_more-articles_related-content-card">Conquer your MS 365 bill and reduce your overall cost.</a></h3>
                        <p>
                            5 Tips on how to manage control your MS 365 Bill
                            1.Don’t over provision or purchase licenses
                        </p>
                        <a class="read-more" href="https://www.linkedin.com/pulse/conquer-your-office-365-bill-reduce-overall-cost-chris-cabezudo?trk=pulse-article_more-articles_related-content-card">Read More <i class="bx bx-chevron-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
