<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <h2>FAQ</h2>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Pages</li>
                        <li>FAQ</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="protected-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Frequently Asked Questions</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum suspendisse.</p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="frequently-content-wrap">
                    <ul>
                        <li>
                            <i class="flaticon-database-1"></i>
                            <h3>What Payment Methods Are Available?</h3>
                            <p>Lorem ipsum consectetur dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun.</p>
                        </li>
                        <li>
                            <i class="flaticon-diagram"></i>
                            <h3>How Do I Buy A Domain Name?</h3>
                            <p>Lorem ipsum consectetur dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun.</p>
                        </li>
                        <li>
                            <i class="flaticon-uptime"></i>
                            <h3>Let’s Find A Hosting Office Near You?</h3>
                            <p>Lorem ipsum consectetur dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun.</p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="frequently-content-wrap">
                    <ul>
                        <li>
                            <i class="flaticon-migrating"></i>
                            <h3>Which Plan Is Right For Me?</h3>
                            <p>Lorem ipsum consectetur dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun.</p>
                        </li>
                        <li>
                            <i class="flaticon-network-1"></i>
                            <h3>Is Max Hosting Reliable?</h3>
                            <p>Lorem ipsum consectetur dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun.</p>
                        </li>
                        <li>
                            <i class="flaticon-database"></i>
                            <h3>How To Buy This?</h3>
                            <p>Lorem ipsum consectetur dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="faq-contact-area pb-100">
    <div class="container">
        <div class="ask-question">
            <div class="section-title">
                <h2>Ask Question</h2>
            </div>

            <form id="contactForm">
                <div class="row">
                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input type="text" name="name" class="form-control" placeholder="Your Name">
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input type="email" name="email" class="form-control" placeholder="Your Email">
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input type="text" name="phone-number" class="form-control" placeholder="Your Phone">
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input type="text" name="msg-subject" class="form-control" placeholder="Your Subject">
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <textarea name="message" class="form-control" cols="30" rows="5" placeholder="Your Message"></textarea>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <button type="submit" class="default-btn btn-two"><span class="label">Send Message</span></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>