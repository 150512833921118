<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <h2>Our Team</h2>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Pages</li>
                        <li>Our Team</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="our-team-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <img src="assets/img/team/team-bg.png" alt="Image">

                    <div class="team-man">
                        <img src="assets/img/team/team-img-one.png" alt="Image">
                    </div>

                    <h3>Jenifar Ikra</h3>
                    <span>Head of Idae</span>

                    <ul class="social-icon">
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-youtube"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <img src="assets/img/team/team-bg.png" alt="Image">

                    <div class="team-man">
                        <img src="assets/img/team/team-img-two.png" alt="Image">
                    </div>
                    <h3>Jenia Popy</h3>
                    <span>UI/UX Designer</span>

                    <ul class="social-icon">
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-youtube"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <img src="assets/img/team/team-bg.png" alt="Image">

                    <div class="team-man">
                        <img src="assets/img/team/team-img-three.png" alt="Image">
                    </div>
                    <h3>Alisa Merina</h3>
                    <span>Full Stack Dev</span>

                    <ul class="social-icon">
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-youtube"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <img src="assets/img/team/team-bg.png" alt="Image">

                    <div class="team-man">
                        <img src="assets/img/team/team-img-four.png" alt="Image">
                    </div>
                    <h3>Hearts Mith</h3>
                    <span>Full Stack Dev</span>

                    <ul class="social-icon">
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-youtube"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <img src="assets/img/team/team-bg.png" alt="Image">

                    <div class="team-man">
                        <img src="assets/img/team/team-img-five.png" alt="Image">
                    </div>

                    <h3>Kiat Dew</h3>
                    <span>Full Stack Dev</span>

                    <ul class="social-icon">
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-youtube"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <img src="assets/img/team/team-bg.png" alt="Image">

                    <div class="team-man">
                        <img src="assets/img/team/team-img-six.png" alt="Image">
                    </div>

                    <h3>Jest Merina</h3>
                    <span>Full Stack Dev</span>

                    <ul class="social-icon">
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-youtube"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <img src="assets/img/team/team-bg.png" alt="Image">

                    <div class="team-man">
                        <img src="assets/img/team/team-img-seven.png" alt="Image">
                    </div>

                    <h3>Philip Wilson</h3>
                    <span>Marketing Head</span>

                    <ul class="social-icon">
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-youtube"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <img src="assets/img/team/team-bg.png" alt="Image">
                    
                    <div class="team-man">
                        <img src="assets/img/team/team-img-one.png" alt="Image">
                    </div>

                    <h3>Ann Smith</h3>
                    <span>Finance Lead</span>

                    <ul class="social-icon">
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-youtube"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-team">
                    <img src="assets/img/team/team-bg.png" alt="Image">

                    <div class="team-man">
                        <img src="assets/img/team/team-img-eight.png" alt="Image">
                    </div>

                    <h3>Richard Ford</h3>
                    <span>Instructor of Mathematics</span>

                    <ul class="social-icon">
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-youtube"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="expert-team-area pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our Expert Team</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum suspendisse.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-team">
                    <img src="assets/img/team/team-img-nine.jpg" alt="Image">

                    <div class="team-content">
                        <h3>Jonkin Jullinor</h3>
                        <span>Product supplier</span>
                    </div>

                    <ul>
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        
                        <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-team">
                    <img src="assets/img/team/team-img-ten.jpg" alt="Image">

                    <div class="team-content">
                        <h3>Naimuk Waninolin</h3>
                        <span>Office executive support</span>
                    </div>

                    <ul>
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        
                        <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-team">
                    <img src="assets/img/team/team-img-eleven.jpg" alt="Image">

                    <div class="team-content">
                        <h3>Moris Julfikar</h3>
                        <span>Regional supplier</span>
                    </div>

                    <ul>
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        
                        <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-team">
                    <img src="assets/img/team/team-img-twelve.jpg" alt="Image">

                    <div class="team-content">
                        <h3>Waxin Alexgander</h3>
                        <span>International supplier</span>
                    </div>

                    <ul>
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        
                        <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-team">
                    <img src="assets/img/team/team-img-thirteen.jpg" alt="Image">

                    <div class="team-content">
                        <h3>Waxin Alexgander</h3>
                        <span>International supplier</span>
                    </div>

                    <ul>
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        
                        <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-team">
                    <img src="assets/img/team/team-img-fourteen.jpg" alt="Image">

                    <div class="team-content">
                        <h3>Waxin Alexgander</h3>
                        <span>International supplier</span>
                    </div>

                    <ul>
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        
                        <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>